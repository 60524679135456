export const O_PEACH_PRIMARY = '#FF9B75';
export const O_BLUE_PRIMARY = '#3250B4';
export const O_WHITE_PRIMARY = '#FFF';

export const MAP_DISPLAY_POLYGON_PALETTE = [
  '#0BFF01',
  '#FDFE02',
  '#FE00F6',
  '#011EFE',
  '#13A8FE',
  '#911EB4',
  '#111111',
  '#AAFFC3',
  '#FE0000',
  '#800000',
];
