import { createWrapper } from 'next-redux-wrapper';
import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';

const devComposer =
  (__isBrowser__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      name: 'Otovo',
      instanceId: '0',
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const composer = process.env.NODE_ENV === 'development' ? devComposer : compose;

function configureStore(initialState) {
  const enhancer = composer(applyMiddleware(thunkMiddleware));
  return createStore(rootReducer, initialState, enhancer);
}

export const reduxWrapper = createWrapper(configureStore);
