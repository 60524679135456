import { Cloud$Package, Cloud$Interest } from '@otovo/shared/types/cloudApi';
import sortPackagesByPanels from './sortPackagesByPanels';
import filterPackagesByHardware from './filterPackagesByHardware';

function hasBatteryPackage(packages: Array<Cloud$Package>) {
  return packages.some((pkg) => !!pkg.price.equipment.batteries.length);
}

export default function getDefaultPackage(
  packages: Array<Cloud$Package>,
  interest: Cloud$Interest,
  preferBatteryPackage?: boolean,
): Cloud$Package {
  const withBattery = !!preferBatteryPackage && hasBatteryPackage(packages);

  const packagesWithPreferredHardware = filterPackagesByHardware({
    packages,
    battery: withBattery,
  });

  // This should never happen, as we should have at least one package at this point.
  if (!packagesWithPreferredHardware.length) {
    throw new Error(
      `Unable to find package - no valid packages in interest ${interest.id}`,
    );
  }

  const sortedPackages = sortPackagesByPanels(packagesWithPreferredHardware);

  if (
    sortedPackages.length > 1 &&
    interest.default_package_size === 'economic'
  ) {
    return sortedPackages[1];
  }

  return sortedPackages[0];
}
