import { RainbowTheme } from './types/rainbow';

export function resolveTheming(
  buSlug: string,
  theme: RainbowTheme,
  partner?: string,
): RainbowTheme {
  if (partner) {
    switch (partner) {
      case 'fastweb-it':
        return {
          ...theme,
          alias: {
            radii: {
              radius_100: '4px',
              radius_200: '8px',
              radius_300: '999px',
            },
            /** Semantic aliases */
            background_100: '#fdfbf7',
            background_200: '#fff5cc',
            background_300: '#feecad',
            background: {
              contrast_100: '#f2a800',
              contrast_200: '#dcbc72',
            },
            text_100: '#f2a800',
            text_200: '#5b52cf',
            text_300: '#ffffff',
            border_100: '#f2a800',
            border: {
              contrast_100: '#c1c7cd',
              contrast_200: '#5b5a53',
            },
            states: {
              success: '#fddc00', // have to be Hex because of hexToRGB()
              neutral: '#fff8cc',
              warning: '#ffc107',
              danger: '#ff4b3d',
            },
          },
          gradients: {
            ...theme.gradients,
            variant1: 'linear-gradient(101deg, #000, #000)',
            variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
            variant3: 'linear-gradient(to right, #FDDC00, #e4c600)',
          },
        };
      case 'leroymerlin-it':
        return {
          ...theme,
          alias: {
            radii: {
              radius_100: '4px',
              radius_200: '4px',
              radius_300: '20px',
            },
            background_100: '#e6e6e6',
            background_200: '#ebf5de',
            background_300: '#ebf5de',
            text_100: '#46a610',
            text_200: '#FD0808',
            text_300: '#fff',
            border_100: '#188803',
            border: {
              contrast_100: '#b3b3b3',
              contrast_200: '#c5e39e',
            },
            background: {
              contrast_100: '#188803',
              contrast_200: '#46a610',
            },
            states: {
              success: '#46a610',
              neutral: '#0b96cc',
              warning: '#ea7315',
              danger: '#ea302d',
            },
          },
          gradients: {
            ...theme.gradients,
            variant1: 'linear-gradient(101deg, #78be20, #78be20)',
            variant2: `linear-gradient(0deg, #fff 19.85%, #fff 118.93%)`, // secondary button
            variant3: 'linear-gradient(to right, #46a610, #46a610)',
          },
        };
      default:
        break;
    }
  }
  switch (buSlug) {
    case 'telinet-se':
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          /** Semantic aliases */
          background_100: '#F7FCFD',
          background_200: '#EFF9FA',
          background_300: '#E8F6F7',
          background: {
            contrast_100: '#2DC0D0',
            contrast_200: '#7FD3DC',
          },
          text_100: '#2DC0D0',
          text_200: '#8ACED6',
          text_300: '#ffffff',
          border_100: '#2DC0D0',
          border: {
            contrast_100: '#D9F0F2',
            contrast_200: '#C2E7EA',
          },
          states: {
            success: '#55DC8C', // have to be Hex because of hexToRGB()
            neutral: '#D0F1DD',
            warning: '#FFC107',
            danger: '#A30013',
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(101deg, #0A416E, #072F4F)',
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(to right, #55DC8C, #26BA63)',
        },
      };
    case 'vivi-it':
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          /** Semantic aliases */
          background_100: 'hsl(296, 24%, 98%)',
          background_200: 'hsl(296, 28%, 96%)',
          background_300: 'hsl(296, 32%, 94%)',
          background: {
            contrast_100: 'hsl(284, 57%, 39%)',
            contrast_200: 'hsl(301, 86%, 32%)',
          },
          text_100: 'hsl(285, 42%, 39%)',
          text_200: 'hsl(285, 37%, 52%)',
          text_300: '#ffffff',
          border_100: 'hsl(301, 86%, 32%)',
          border: {
            contrast_100: 'hsl(303, 34%, 88%)',
            contrast_200: 'hsl(300, 26%, 78%)',
          },
          states: {
            success: '#4BC485', // have to be Hex because of hexToRGB()
            neutral: 'hsl(303, 41%, 90%)',
            warning: 'hsl(33, 100%, 50%)',
            danger: 'hsl(336, 100%, 50%)',
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: `linear-gradient(101deg, hsl(280, 38%, 39%), hsl(279, 38%, 22%))`,
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: `linear-gradient(to right, hsl(202, 100%, 38%), hsl(297, 65%, 34%))`,
        },
      };
    case 'santander-br':
      return {
        ...theme,
        /** Semantic aliases */
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          background_100: 'hsla(113, 34%, 98%, 1)',
          background_200: 'hsla(113, 32%, 96%, 1)',
          background_300: 'hsla(113, 30%, 94%, 1)',
          background: {
            contrast_100: 'hsla(113, 34%, 58%, 1)',
            contrast_200: 'hsla(113, 39%, 53%, 1)',
          },
          text_100: 'hsla(113, 32%, 44%, 1)',
          text_200: 'hsla(113, 15%, 55%, 1)',
          text_300: '#ffffff',
          border_100: 'hsla(113, 32%, 44%, 1)',
          border: {
            contrast_100: 'hsla(113, 29%, 86%, 1)',
            contrast_200: 'hsla(112, 29%, 78%, 1)',
          },
          states: {
            success: '#64BA69', // have to be Hex
            neutral: 'hsla(112, 29%, 90%, 1)',
            warning: 'hsl(45, 100%, 60%)',
            danger: 'hsl(0, 100%, 40%)',
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: `linear-gradient(to right, hsla(111, 29%, 45%, 1), hsla(111, 29%, 31%, 1))`,
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: `linear-gradient(to right, hsla(113, 34%, 58%, 1), hsla(111, 29%, 45%, 1))`,
        },
      };
    case 'iberdrola-fr':
      return {
        ...theme,
        /** Semantic aliases */
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          background_100: 'hsl(80, 37%, 97%)',
          background_200: 'hsl(80, 34%, 94%)',
          background_300: 'hsl(80, 34%, 91%)',
          background: {
            contrast_100: 'hsl(84, 68%, 32%)',
            contrast_200: 'hsl(84, 68%, 37%)',
          },
          text_100: 'hsl(84, 95%, 25%)',
          text_200: 'hsla(84, 20%, 50%)',
          text_300: '#ffffff',
          border_100: 'hsl(84, 95%, 25%)',
          border: {
            contrast_100: 'hsla(80, 28%, 48%, .3)',
            contrast_200: 'hsla(80, 28%, 48%, .5)',
          },
          states: {
            success: '#5D891A', // have to be Hex because of hexToRGB()
            neutral: 'hsl(80, 34%, 91%)',
            warning: 'hsl(29, 79%, 48%)',
            danger: 'hsl(350, 99%, 35%)',
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: `linear-gradient(101deg, #476917, #2A3F0E)`,
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: `linear-gradient(to right, hsl(84, 68%, 38%), hsl(84, 68%, 32%))`,
        },
      };
    case 'alterna-es':
      return {
        ...theme,
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(90deg, #06114A 0%, #061164 100%)',
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(90deg, #FFB422 0%, #FFAA05 100%)',
        },
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          background_100: '#F9FAFF',
          background_200: '#F3F4FF',
          background_300: '#EBEEFF',
          background: {
            contrast_100: '#F09E00',
            contrast_200: '#FFB422',
          },
          text_100: '#06114A',
          text_200: '#4755A0',
          text_300: '#ffffff',
          border_100: '#4755A0',
          border: {
            contrast_100: '#D1D7F8',
            contrast_200: '#A7B3FF',
          },
          states: {
            success: '#B2D329', // have to be Hex because of hexToRGB()
            neutral: '#FFECC7',
            warning: '#FFB422',
            danger: '#D80E36',
          },
        },
      };
    case 'butik-es':
      return {
        ...theme,
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(90deg, #06114A 0%, #061164 100%)',
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(90deg, #677BE7 0%, #5369e4 100%)',
        },
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          background_100: '#F9FAFF',
          background_200: '#F3F4FF',
          background_300: '#EBEEFF',
          background: {
            contrast_100: '#677BE7',
            contrast_200: '#FFB422',
          },
          text_100: '#06114A',
          text_200: '#4755A0',
          text_300: '#ffffff',
          border_100: '#677BE7',
          border: {
            contrast_100: '#D1D7F8',
            contrast_200: '#A7B3FF',
          },
          states: {
            success: '#677BE7', // have to be Hex
            neutral: '#FFECC7',
            warning: '#FFB422',
            danger: '#D80E36',
          },
        },
      };
    case 'energygo-es':
      return {
        /** Default theme is defined inside rainbow */
        ...theme,
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(90deg, #3EA200 0%, #017636 100%)', // stickyheader
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(90deg, #3EA200 0%, #41a805 100%)', // button
        },
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          background_100: '#FCFCFC', // muted
          background_200: '#f7f7f7', // +2
          background_300: '#ECF6E6', // +1
          background: {
            contrast_100: '#3b9900', // button bg
            contrast_200: '#FFB422',
          },
          text_100: '#06114A',
          text_200: '#4755A0',
          text_300: '#ffffff',
          border_100: '#3EA200',
          border: {
            contrast_100: '#F2F2F2',
            contrast_200: '#e0e0e0',
          },
          states: {
            success: '#3EA200', // have to be Hex because of hexToRGB()
            neutral: '#FFECC7',
            warning: '#FFB422',
            danger: '#D80E36',
          },
        },
      };
    case 'imaginaenergia-es':
      return {
        ...theme,
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(90deg, #F26522 0%, #F26522 100%)', // stickyheader
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(90deg, #e2432b 20%, #f5a036 100%)', // button
        },
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          background_100: '#fffbfa', // muted
          background_200: '#fff3f0', // +2
          background_300: '#ffebe6', // +1
          background: {
            contrast_100: '#e2432b', // button bg
            contrast_200: '#FFB422',
          },
          text_100: '#06114A',
          text_200: '#4755A0',
          text_300: '#ffffff',
          border_100: '#E14F29', // var(--naranja)
          border: {
            contrast_100: '#ffebe6',
            contrast_200: '#FFB422',
          },
          states: {
            success: '#ff420c', // have to be Hex because of hexToRGB()
            neutral: '#ffdd99',
            warning: '#fdc103', // from logo - OK?
            danger: '#ff1d0c', // from logo - OK?
          },
        },
      };
    case 'lucera-es':
      return {
        ...theme,
        gradients: {
          ...theme.gradients,
          variant1:
            // hsl(158, 100%, 98%) // base
            // 'linear-gradient(90deg, hsl(160, 98%, 37%) 0%, hsl(160, 98%, 37%) 100%)', // stickyheader
            'linear-gradient(90deg, #001a4d 0%, #001a4d 100%)', // stickyheader
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(90deg, #00FFAA 0%, #00FFAA 100%)', // button
        },
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          background_100: 'hsl(160, 98%, 98%)', // muted
          background_200: 'hsl(160, 98%, 95%)', // +2
          background_300: 'hsl(160, 80%, 90%)', // +1
          background: {
            contrast_100: '#03ee9f', // button bg
            contrast_200: '#00FFAA',
          },
          text_100: '#001a4d',
          text_200: 'hsl(220 100%  15% / 0.8)',
          text_300: '#001a4d',
          border_100: 'hsl(160, 92%, 40%)',
          border: {
            contrast_100: 'hsl(160, 60%, 90%)',
            contrast_200: 'hsl(160, 60%, 60%)',
          },
          states: {
            success: '#03ee9f', // have to be Hex because of hexToRGB()
            neutral: '#E3E8E6',
            warning: '#fdc103',
            danger: '#da1e28',
          },
        },
      };
    case 'masmovil-es':
      return {
        ...theme,
        gradients: {
          ...theme.gradients,
          variant1:
            'linear-gradient(90deg, hsl(53, 0%, 20%) 0%, hsl(53, 0%, 0%) 100%)', // stickyheader
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3:
            'linear-gradient(90deg, hsl(97, 100%, 32%) 0%, hsl(97, 100%, 32%) 100%)', // button
        },
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '6px',
            radius_300: '8px',
          },
          background_100: 'hsl(53, 98%, 95%)', // muted
          background_200: 'hsl(53, 98%, 91%)', // +2
          background_300: 'hsl(53, 100%, 50%)', // +1
          background: {
            contrast_100: 'hsl(97, 100%, 35%)', // button bg
            contrast_200: 'hsl(97, 100%, 38%)',
          },
          text_100: '#333333', // btn group text
          text_200: '#444a4f',
          text_300: '#fff', // stickyheader - left content text color
          border_100: '#000', // icon colors
          border: {
            contrast_100: 'hsl(53 0% 0% / 0.1)', // btn secondary border, borders, unselected in btn group
            contrast_200: 'hsl(0 0% 40%)',
          },
          states: {
            success: '#ffe200', // have to be Hex because of hexToRGB()
            neutral: 'hsl(53, 45%, 85%)',
            warning: '#fdc103',
            danger: '#ee001d',
          },
        },
      };
    case 'enerviva-it':
      return {
        ...theme,
        gradients: {
          ...theme.gradients,
          variant1: `linear-gradient(101deg, #104035, #034638)`,
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`,
          variant3: '#50c800',
        },
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '6px',
            radius_300: '999px',
          },
          background_100: '#FAFAFF',
          background_200: '#F5F6FF',
          background_300: '#EDEFFF',
          background: {
            contrast_100: '#0bbd58',
            contrast_200: '#50c800',
          },
          text_100: '#0bbd58',
          text_200: '#7A7CA3',
          text_300: '#ffffff',
          border_100: '#0bbd58',
          border: {
            contrast_100: '#7A7CA330',
            contrast_200: '#A0BAF6',
          },
          states: {
            success: '#11EFA1',
            neutral: '#E3E8E6',
            warning: '#fdc103',
            danger: '#da1e28',
          },
        },
      };
    case 'oktavia-it':
      // Main brand colors: #8565ff and #bdf347
      return {
        ...theme,
        gradients: {
          ...theme.gradients,
          variant1: '#8565ff', // stickyheader
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`,
          variant3: 'linear-gradient(90deg, #CC33FF 0%, #6633FF 100%)', // button
        },
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          background_100: 'hsl(79,88%, 99%)', // muted
          background_200: 'hsl(79,88%, 97%)', // +2
          background_300: 'hsl(79,88%, 89%)', // +1
          background: {
            contrast_100: '#CC33FF', // button bg
            contrast_200: '#ffb422',
          },
          text_100: '#06114a',
          text_200: '#4755a0',
          text_300: '#ffffff',
          border_100: '#8565ff',
          border: {
            contrast_100: 'hsl(255, 100%, 92%)',
            contrast_200: 'hsl(255, 100%, 85%)',
          },
          states: {
            success: '#8565ff',
            neutral: '#ffecc7',
            warning: '#ffb422',
            danger: '#d80e36',
          },
        },
      };
    default:
      return {
        /** Default theme is defined inside rainbow */
        ...theme,
        gradients: {
          ...theme.gradients,
          variant1: `linear-gradient(101deg, #1e1e4a, ${theme.colors.lilac_100})`,
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: `linear-gradient(to right, #3156c4, #1e40b4)`, // primary button
        },
        alias: {
          radii: {
            radius_100: theme.radii[2], // 4px
            radius_200: theme.radii[3], // 8px
            radius_300: theme.radii.pill, // 9999px
          },
          background_100: theme.colors.lilac_1, // #FAFAFF
          background_200: theme.colors.lilac_5, // #F5F6FF
          background_300: theme.colors.lilac_10, // #EDEFFF
          background: {
            contrast_100: theme.colors.blue_60, // #1E40B4
            contrast_200: theme.colors.blue_50, // #3156C4
          },
          text_100: theme.colors.blue_60, // #1E40B4 btn:secondary:tertiary text
          text_200: theme.colors.lilac_60, // #7A7CA3
          text_300: '#ffffff', // stickyheader - left content text color, btn:primary text
          border_100: theme.colors.blue_60, // #1E40B4
          border: {
            contrast_100: `${theme.colors.lilac_60}30`, // #7A7CA330
            contrast_200: `${theme.colors.blue_30}`, // #A0BAF6
          },
          states: {
            success: theme.colors.green_40, // #11EFA1 have to be Hex because of hexToRGB()
            neutral: theme.colors.lilac_10, // #EDEFFF
            warning: theme.colors.peach_50, // #FE8F65
            danger: theme.colors.red_50, // #E23B3E
          },
        },
      };
  }
}
