import { Cloud$LoanApplication } from '@otovo/shared/types/cloudApi';
import {
  REQUEST_LOAN_APPLICATION,
  REQUEST_LOAN_APPLICATION_SUCCESS,
  REQUEST_LOAN_APPLICATION_FAILED,
  START_EXTERNAL_LOAN_APPLICATION,
  START_EXTERNAL_LOAN_APPLICATION_SUCCESS,
  START_EXTERNAL_LOAN_APPLICATION_FAILED,
  POLL_LOAN_APPLICATION_START,
  POLL_LOAN_APPLICATION_COMPLETE,
  POLL_LOAN_APPLICATION_CANCELED,
} from './actionTypes';

type LoanActionTypes =
  | { readonly type: typeof REQUEST_LOAN_APPLICATION }
  | {
      readonly type: typeof REQUEST_LOAN_APPLICATION_SUCCESS;
      readonly json: Cloud$LoanApplication;
    }
  | { readonly type: typeof REQUEST_LOAN_APPLICATION_FAILED }
  | { readonly type: typeof START_EXTERNAL_LOAN_APPLICATION }
  | {
      readonly type: typeof START_EXTERNAL_LOAN_APPLICATION_SUCCESS;
    }
  | { readonly type: typeof START_EXTERNAL_LOAN_APPLICATION_FAILED }
  | { readonly type: typeof POLL_LOAN_APPLICATION_START }
  | {
      readonly type: typeof POLL_LOAN_APPLICATION_COMPLETE;
    }
  | { readonly type: typeof POLL_LOAN_APPLICATION_CANCELED };

export type LoanStore = {
  readonly isFetching: boolean;
  readonly fetchFailed: boolean;
  readonly fetchSuccess: boolean;
  readonly loanApplication: Cloud$LoanApplication | null | undefined;
  readonly isPolling: boolean;
  readonly pollFailed: boolean;
  readonly pollSuccess: boolean;
};

const initialLoanState = {
  isFetching: false,
  fetchFailed: false,
  fetchSuccess: false,
  loanApplication: null,
  isPolling: false,
  pollFailed: false,
  pollSuccess: false,
};

export default function loanReducer(
  state: LoanStore | undefined = initialLoanState,
  action: LoanActionTypes,
): LoanStore {
  switch (action.type) {
    case REQUEST_LOAN_APPLICATION:
      return {
        ...state,
        isFetching: true,
        fetchFailed: false,
      };

    case REQUEST_LOAN_APPLICATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetchSuccess: true,
        loanApplication: action.json,
      };

    case REQUEST_LOAN_APPLICATION_FAILED:
      return {
        ...state,
        isFetching: false,
        fetchSuccess: true,
        fetchFailed: true,
      };
    case POLL_LOAN_APPLICATION_START:
      return {
        ...state,
        pollFailed: false,
        pollSuccess: false,
        isPolling: true,
      };
    case POLL_LOAN_APPLICATION_COMPLETE:
      return {
        ...state,
        pollSuccess: true,
        isPolling: false,
      };
    case POLL_LOAN_APPLICATION_CANCELED:
      return {
        ...state,
        pollFailed: true,
        isPolling: false,
      };
    default:
      return state;
  }
}
