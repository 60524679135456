import { Cloud$Product, Cloud$Package } from '@otovo/shared/types/cloudApi';
import { Surface$Surface } from '@otovo/shared/types/surfaceTypes';

import { MAP_DISPLAY_POLYGON_PALETTE } from '../../../styles/color_constants';

import { SurfaceConfiguration } from '../type';

function flatten(array: Array<any>) {
  return [].concat(...array);
}

function getRelatedSpecSurface({ surfaceId, products, selectedPackage }) {
  // Prefer use of the selectedPackage
  if (selectedPackage) {
    const relatedSpecSurface = selectedPackage.price.surfaces.find(
      (s) => s.surface.id === surfaceId,
    );
    if (relatedSpecSurface) {
      return relatedSpecSurface;
    }
  }
  const allPackages = flatten(products.map((product) => product.packages));
  const allPrices = flatten(allPackages.map((pkg) => pkg.price));
  const allSurfaces = flatten(allPrices.map((price) => price.surfaces));

  return allSurfaces.find((surface) => surface.surface.id === surfaceId);
}

type Args = {
  surface: Surface$Surface;
  index: number;
  products: Array<Cloud$Product>;
  selectedPackage: Cloud$Package | null | undefined;
};

export default ({
  surface,
  index,
  products,
  selectedPackage,
}: Args): SurfaceConfiguration => {
  const relatedSpecSurface = getRelatedSpecSurface({
    surfaceId: surface.id,
    products,
    selectedPackage,
  });
  return {
    surfaceId: surface.id,
    isOutline: surface.is_outline,
    isSelected: !!relatedSpecSurface,
    isFake: surface.is_fake,
    roofing: relatedSpecSurface
      ? relatedSpecSurface.roofing
      : surface.default_mounting_surface,
    color:
      MAP_DISPLAY_POLYGON_PALETTE[index % MAP_DISPLAY_POLYGON_PALETTE.length],
    maxNumPanels: surface.max_num_panels || 0,
  };
};
