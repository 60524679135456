import { Cloud$Interest, Cloud$Package } from '@otovo/shared/types/cloudApi';
import { SurfaceConfiguration } from '../type';

import surfaceConfigurationMapper from './surfaceConfigurationMapper';
import { validateScore } from './surfaceScore';

export default function getSurfaceConfigurationList(
  interest: Cloud$Interest,
  selectedPackage?: Cloud$Package | null,
): Array<SurfaceConfiguration> {
  return interest.available_surfaces
    .filter(validateScore)
    .map((surface, index) =>
      surfaceConfigurationMapper({
        surface,
        index,
        selectedPackage,
        products: interest.products,
      }),
    );
}
