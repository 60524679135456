/**
 * `utmKeyMapping` maps the UTM keys we expect in Cloud (object keys) to their
 *  possible query parameter equivalents, including shorthand syntax (the
 *  arrays).
 */

export const utmKeyMapping = {
  utm_source: ['utm_source', 'cs'],
  utm_medium: ['utm_medium', 'cm'],
  utm_campaign: ['utm_campaign', 'cn'],
  utm_term: ['utm_term', 'ck'],
  utm_content: ['utm_content', 'cc'],
  utm_id: ['utm_id', 'ci'],
  // custom fields added for Holu
  utm_channel: ['utm_channel'],
  utm_target: ['utm_target'],
  utm_level: ['utm_level'],
};

export type UTMKeys = keyof typeof utmKeyMapping;
