import { BodyS } from '@otovo/rainbow';

type Props = {
  children: React.ReactNode;
};

const BannerContent = ({ children }: Props) => {
  return (
    <BodyS my={0} color="inherit" pr="6" mx="auto">
      {children}
    </BodyS>
  );
};

export default BannerContent;
