import {
  REQUEST_REGISTER_SSN,
  REQUEST_REGISTER_SSN_SUCCESS,
  REQUEST_REGISTER_SSN_FAILED,
} from './actionTypes';

export type UserProfileStore = {
  readonly isRegistering: boolean;
  readonly registeringFailed: boolean;
  readonly registeringSuccess: boolean;
};

type Action =
  | { readonly type: typeof REQUEST_REGISTER_SSN }
  | { readonly type: typeof REQUEST_REGISTER_SSN_FAILED }
  | { readonly type: typeof REQUEST_REGISTER_SSN_SUCCESS };

const initialState: UserProfileStore = {
  isRegistering: false,
  registeringFailed: false,
  registeringSuccess: false,
};

export default function userProfileReducer(
  state: UserProfileStore | undefined = initialState,
  action: Action,
):
  | UserProfileStore
  | {
      isRegistering: boolean;
      registeringFailed: boolean;
      registeringSuccess: boolean;
    } {
  switch (action.type) {
    case REQUEST_REGISTER_SSN:
      return {
        ...state,
        isRegistering: true,
        registeringSuccess: false,
        registeringFailed: false,
      };
    case REQUEST_REGISTER_SSN_SUCCESS:
      return {
        ...state,
        isRegistering: false,
        registeringSuccess: true,
      };
    case REQUEST_REGISTER_SSN_FAILED:
      return {
        ...state,
        isRegistering: false,
        registeringSuccess: false,
        registeringFailed: true,
      };

    default:
      return state;
  }
}
