import { MessageDescriptor } from 'react-intl';
import { Box } from '@otovo/rainbow';

import ContentWrap from '../ContentWrap';
import AnimationWrapper from './AnimationWrapper';

type Props = {
  children: React.ReactNode;
  isDismissed: boolean;
  onDismiss?: (message: string | MessageDescriptor) => void;
  type: 'error' | 'success' | 'cta';
};

const NotificationBanner = ({
  children,
  onDismiss,
  isDismissed,
  type,
}: Props) => {
  if (isDismissed) {
    return null;
  }
  return (
    <AnimationWrapper isDismissed={isDismissed} type={type}>
      <ContentWrap>
        {onDismiss && (
          <Box
            as="button"
            position="absolute"
            right={0}
            top={0}
            bottom={0}
            background="transparent"
            border="none"
            onClick={(message: string) => {
              onDismiss(message);
            }}
            color="currentColor"
          >
            <Box
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 21 21"
              as="svg"
              width="6"
              height="6"
              fill="none"
              color="currentColor"
            >
              <svg>
                <circle cx="10.5" cy="10.5" r="10.5" fill="inherit" />
                <path
                  fill="currentColor"
                  d="M6.186 13.985l7.757-7.757 1.12 1.12-7.756 7.757z"
                />
                <path
                  fill="currentColor"
                  d="M13.943 15.106L6.186 7.35l1.12-1.122 7.758 7.757z"
                />
              </svg>
            </Box>
          </Box>
        )}
        {children}
      </ContentWrap>
    </AnimationWrapper>
  );
};

NotificationBanner.defaultProps = {
  onDismiss: undefined,
  type: undefined,
};
export default NotificationBanner;
