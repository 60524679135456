import {
  UPLOAD,
  UPLOAD_FAILED,
  UPLOAD_SUCCESS,
  DROP_REJECTED,
  UPLOADER_RESET,
} from './actionTypes';

type Template = {
  readonly isUploading: boolean;
  readonly uploadSuccess: boolean;
  readonly uploadFailed: boolean;
  readonly fileDropRejected: boolean;
};

export type FileUploaderStore = {
  readonly template: Template;
  [key: string]: Template;
};

type Actions =
  | { readonly type: typeof UPLOAD; readonly id: string }
  | { readonly type: typeof UPLOAD_FAILED; readonly id: string }
  | { readonly type: typeof UPLOAD_SUCCESS; readonly id: string }
  | { readonly type: typeof DROP_REJECTED; readonly id: string }
  | { readonly type: typeof UPLOADER_RESET; readonly id: string };

const initialState = {
  template: {
    fileDropRejected: false,
    isUploading: false,
    uploadSuccess: false,
    uploadFailed: false,
  },
};

function getInstanceState(id: string, state: FileUploaderStore) {
  return state[id] || initialState.template;
}

const reducer = (
  state: FileUploaderStore = initialState,
  action: Actions,
): FileUploaderStore | { template: Template } => {
  switch (action.type) {
    case DROP_REJECTED:
      return {
        ...state,
        [action.id]: {
          ...getInstanceState(action.id, state),
          fileDropRejected: true,
        },
      };

    case UPLOAD:
      return {
        ...state,
        [action.id]: {
          ...getInstanceState(action.id, state),
          isUploading: true,
          fileDropRejected: false,
          uploadFailed: false,
          uploadSuccess: false,
        },
      };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        [action.id]: {
          ...getInstanceState(action.id, state),
          isUploading: false,
          uploadSuccess: true,
        },
      };
    case UPLOAD_FAILED:
      return {
        ...state,
        [action.id]: {
          ...getInstanceState(action.id, state),
          isUploading: false,
          uploadFailed: true,
        },
      };
    case UPLOADER_RESET:
      return {
        ...state,
        [action.id]: {
          ...getInstanceState(action.id, state),
          fileDropRejected: false,
          isUploading: false,
          uploadSuccess: false,
          uploadFailed: false,
        },
      };
    default:
      return state;
  }
};

export default reducer;
