import { css, Global, ThemeProvider } from '@emotion/react';
import { theme as defaultTheme } from '@otovo/rainbow';
import dynamic from 'next/dynamic';
import LanguageProvider from 'otovoweb/src/components/LanguageProvider';
import { useEffect } from 'react';
import { SWRConfig } from 'swr';
import GlobalAlert from './components/GlobalAlert/GlobalAlert';
import { GLOBAL_ALERT } from './config/featureToggles/constants';
import { isEnabled } from './config/featureToggles/utils';
import { OtovoConfigContext } from './hooks/configContext';
import { setLocale } from './lib/dayjs';
import { resolveTheming } from './resolveTheming';
import { Otovo$Config } from './types/otovoweb';

const fetcher = (url) => fetch(url).then((res) => res.json());

const WeAreHiring = dynamic(() => import('./components/WeAreHiring'));

type NextPageRootProps = {
  children: React.ReactNode;
  configValues: Otovo$Config;
  enableTheming?: boolean;
};

const NextPageRoot = ({
  children,
  configValues,
  enableTheming = true,
}: NextPageRootProps) => {
  const {
    BU_CONFIG: { slug, locale, affiliate },
  } = configValues;
  useEffect(() => {
    setLocale(locale);
  }, [locale]);

  const theme = enableTheming
    ? resolveTheming(slug, defaultTheme, affiliate?.slug)
    : defaultTheme;

  return (
    <OtovoConfigContext.Provider value={configValues}>
      <LanguageProvider config={configValues}>
        <>
          <Global
            styles={css({
              '.react-autosuggest__suggestion--highlighted': {
                backgroundColor: theme.alias.background_100,
              },
            })}
          />
          {/* @ts-ignore */}
          <SWRConfig
            value={{
              fetcher,
            }}
          >
            <ThemeProvider theme={theme}>
              {isEnabled(GLOBAL_ALERT, configValues) && <GlobalAlert />}

              {children}
            </ThemeProvider>
          </SWRConfig>
        </>
      </LanguageProvider>
      <WeAreHiring />
    </OtovoConfigContext.Provider>
  );
};

export default NextPageRoot;
