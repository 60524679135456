import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction, combineReducers } from 'redux';

import fileUploaderReducer, {
  FileUploaderStore,
} from 'otovoweb/src/components/FileUploader/reducer';
import interestStore from 'otovoweb/src/stores/Interest/reducer';
import { InterestStore } from 'otovoweb/src/stores/Interest/type';
import loanReducer, { LoanStore } from 'otovoweb/src/stores/Loan/reducer';

import contractStore, {
  ContractStore,
} from 'otovoweb/src/stores/Contract/reducer';

import userProfileReducer, {
  UserProfileStore,
} from 'otovoweb/src/stores/UserProfile/reducer';

export type BreezeReducers = {
  contractStore: ContractStore;
  fileUploaderState: FileUploaderStore;
  interestStore: InterestStore;
  loanStore: LoanStore;
  userProfile: UserProfileStore;
};

const allReducers = combineReducers({
  contractStore,
  fileUploaderState: fileUploaderReducer,
  interestStore,
  loanStore: loanReducer,
  userProfile: userProfileReducer,
});

const rootReducer = (
  state: BreezeReducers,
  action: AnyAction,
): BreezeReducers => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload,
    };
    return nextState;
  }
  return allReducers(state, action);
};

export default rootReducer;
