import log from 'loglevel';

// https://developers.facebook.com/docs/facebook-pixel/reference#events
type FBEventName = 'PageView' | 'ViewContent' | 'Lead' | 'Purchase';
type TrackFields = {
  content_name: string;
  content_type?: string;
  content_category?: string;
  value?: string | number;
  currency?: string;
};
let initialized = false;

function fbPixelInit() {
  if (initialized) {
    log.error('The Facebook Pixel can be initialized one time only.');
    return;
  }

  let n;
  window.fbq = (...args) => {
    if (n.callMethod) {
      n.callMethod(...args);
    } else {
      n.queue.push(args);
    }
  };
  n = window.fbq;

  if (!('_fbq' in window)) {
    // @ts-ignore
    window._fbq = n;
  }
  n.push = n;
  n.loaded = true;
  n.version = '2.0';
  n.queue = [];
  const fbScriptEl = document.createElement('script');
  fbScriptEl.async = true;
  fbScriptEl.src = 'https://connect.facebook.net/en_US/fbevents.js';
  const [firstScriptEl] = Array.from(document.getElementsByTagName('script'));
  if (firstScriptEl && firstScriptEl.parentNode) {
    firstScriptEl.parentNode.insertBefore(fbScriptEl, firstScriptEl);
  }
}

function trackPageView(fbIds: Array<string>) {
  fbIds.forEach((fbId) => {
    window.fbq('init', fbId);
  });

  window.fbq('track', 'PageView');
}

export function track(eventName: FBEventName, trackFields: TrackFields) {
  if (!initialized) {
    log.warn(
      `Unable to track before the pixel is initialized. The ${eventName} event won't be tracked!`,
    );
    return;
  }

  if (window.fbq) {
    window.fbq('track', eventName, trackFields);
  }
}

type FbInitType = {
  fbIds: Array<string>;
};

export function fbInit({ fbIds }: FbInitType) {
  if (!fbIds.length) {
    throw new Error(
      'Unable to initialize Facebook tracking without any pixel IDs configured',
    );
  }
  fbPixelInit();

  initialized = true;

  if (!window.fbq) {
    return;
  }

  trackPageView(fbIds);
}

export function sale({ contentType }: { contentType: string }) {
  track('Purchase', {
    content_type: contentType,
    content_name: 'sale',
    value: String(1),
  });
}
