import { useRouter } from 'next/router';
import log from 'loglevel';
import { useEffect, useState, useRef } from 'react';
import { Cloud$Market } from '@otovo/shared/types/cloudApi';

function useStateRef(initialValue: string) {
  const [value, setValue] = useState(initialValue);
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return [ref, setValue];
}

function createIframeLogger(
  shouldLogCondition: boolean,
): (arg0: string, arg1: Record<string, any> | null | undefined) => void {
  if (shouldLogCondition) {
    return log.info;
  }

  return () => {};
}

function pollForChanges(res, storedIdRef, routeConditions) {
  /**
   * We need to wait with initializing GA until we received the clientId from
   * our parent.
   *
   * This function calls itself every every second until the clientId is set
   *
   */
  if (routeConditions) {
    if (storedIdRef.current) {
      return res(storedIdRef.current);
    }

    return setTimeout(() => {
      return pollForChanges(res, storedIdRef, routeConditions);
    }, 1000);
  }

  return res();
}

function isIframeCompatible(market) {
  // Consider converting to feature toggle
  return market === 'br' || market === 'fr';
}

/**
 * useIframeClientId:
 *
 * Only intended to be used in Brazil/Holu and only for Breeze.
 * This is a temporary solution that we expect to iterate on once we get
 * iframe tracking working properly.
 */
type Props = {
  acceptedDomain: string;
  market: Cloud$Market;
};

function useIframeClientId({
  acceptedDomain,
  market,
}: Props): () => Promise<string | void> {
  const [storedIdRef, setStoredId] = useStateRef('');
  const { pathname, query } = useRouter();
  const { context } = query;
  const logger = createIframeLogger(context === 'iframe');
  const isSearchRoute = /\/search\/?$/.test(pathname);
  useEffect(() => {
    window.addEventListener('message', (event) => {
      const origin = event.origin.replace('www.', '');
      const accepted =
        acceptedDomain?.replace('www.', '') || 'NO_ACCEPTED_DOMAIN';

      if (event.data.destination === 'otovo' || origin === accepted) {
        const { type = '', clientId } = event?.data || {};

        switch (type) {
          case 'SET_CLIENT_ID':
            logger(
              `iframe API: Received clientId <${clientId}>. Initializing GA...`,
              event.data,
            );
            // TS-TODO: is this a real bug/error?
            // @ts-ignore
            setStoredId(clientId);
            break;

          default:
            logger(
              `iframe API: Unknown message type <${
                type || 'undefined'
              }> received from ${acceptedDomain}. Ignoring message with data:`,
              event.data,
            );
            break;
        }
      } else {
        // Ignores messages from untrusted domains
        logger(
          `iframe API: event.data.destination='otovo' is not set or ${origin} doesn't match ${accepted}. Ignoring message from parent`,
          '' as any,
        );
      }
    });
  });
  return () =>
    new Promise<string | void>((res) => {
      pollForChanges(
        res,
        storedIdRef,
        isIframeCompatible(market) && isSearchRoute,
      );
    });
}

export default useIframeClientId;
