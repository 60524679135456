import {
  Cloud$Contract,
  Cloud$LoanApplication,
} from '@otovo/shared/types/cloudApi';
import { REQUEST_LOAN_APPLICATION_SUCCESS } from '../Loan/actionTypes';
import { ContractValidationErrorsType } from './type';

import {
  REQUEST_CONTRACT,
  REQUEST_CONTRACT_SUCCESS,
  REQUEST_CONTRACT_FAILED,
  SIGN_CONTRACT,
  SIGN_CONTRACT_SUCCESS,
  SIGN_CONTRACT_FAILED,
  PREVIEW_CONTRACT,
  PREVIEW_CONTRACT_SUCCESS,
  PREVIEW_CONTRACT_FAILED,
} from './actionTypes';

type SignContractResponse = {
  sign_url: string;
};

export type ContractStore = {
  readonly contract: Cloud$Contract | null | undefined;
  readonly contractValidationErrors: ContractValidationErrorsType;
  readonly fetchFailed: boolean;
  readonly isPosting: boolean;
  readonly responseStatus: number | null | undefined;
  readonly signUrl: string | null | undefined;
  readonly signFailed: boolean;
  readonly isGeneratingContract: boolean;
};

type Action =
  | { readonly type: typeof REQUEST_CONTRACT }
  | {
      readonly type: typeof REQUEST_CONTRACT_FAILED;
      readonly responseStatus: number;
    }
  | {
      readonly type: typeof REQUEST_CONTRACT_SUCCESS;
      readonly json: Cloud$Contract;
    }
  | {
      readonly type: typeof REQUEST_LOAN_APPLICATION_SUCCESS;
      readonly json: Cloud$LoanApplication;
    }
  | {
      readonly type: typeof SIGN_CONTRACT;
    }
  | {
      readonly type: typeof SIGN_CONTRACT_FAILED;
      readonly json: ContractValidationErrorsType;
    }
  | {
      readonly type: typeof SIGN_CONTRACT_SUCCESS;
      readonly json: SignContractResponse;
    }
  | { readonly type: typeof PREVIEW_CONTRACT }
  | { readonly type: typeof PREVIEW_CONTRACT_SUCCESS }
  | {
      readonly type: typeof PREVIEW_CONTRACT_FAILED;
      readonly json: ContractValidationErrorsType;
    };

const initialState: ContractStore = {
  contract: null,
  contractValidationErrors: {},
  fetchFailed: false,
  isPosting: false,
  isGeneratingContract: false,
  responseStatus: null,
  signUrl: null,
  signFailed: false,
};

function handleUpdatedLoanApplication(
  state: ContractStore,
  loanApplication: Cloud$LoanApplication,
): ContractStore {
  const { contract } = state;
  if (!contract) {
    return state;
  }

  const { project } = contract;

  if (!project) {
    return state;
  }

  if (!project || !project.loan_application) {
    return state;
  }

  if (project.loan_application.id === loanApplication.id) {
    return {
      ...state,
      contract: {
        ...contract,
        project: {
          ...project,
          loan_application: loanApplication,
        },
      },
    };
  }

  return state;
}

export default function contractReducer(
  state: ContractStore | undefined = initialState,
  action: Action,
): ContractStore {
  switch (action.type) {
    case REQUEST_CONTRACT:
      return {
        ...state,
        fetchFailed: false,
      };
    case REQUEST_CONTRACT_SUCCESS:
      return {
        ...state,
        contract: action.json,
      };
    case REQUEST_CONTRACT_FAILED:
      return {
        ...state,
        fetchFailed: true,
        responseStatus: action.responseStatus,
      };
    case REQUEST_LOAN_APPLICATION_SUCCESS:
      return handleUpdatedLoanApplication(state, action.json);
    case SIGN_CONTRACT:
      return {
        ...state,
        signFailed: false,
        isPosting: true,
      };
    case SIGN_CONTRACT_SUCCESS:
      return {
        ...state,
        isPosting: false,
        signUrl: action.json.sign_url,
      };
    case SIGN_CONTRACT_FAILED:
      return {
        ...state,
        isPosting: false,
        signFailed: true,
        contractValidationErrors: { ...action.json },
      };
    case PREVIEW_CONTRACT:
      return {
        ...state,
        isGeneratingContract: true,
        contractValidationErrors: {},
      };
    case PREVIEW_CONTRACT_SUCCESS:
      return {
        ...state,
        isGeneratingContract: false,
      };
    case PREVIEW_CONTRACT_FAILED:
      return {
        ...state,
        isGeneratingContract: false,
        contractValidationErrors: { ...action.json },
      };
    default:
      return state;
  }
}
