export const REQUEST_LOAN_APPLICATION = 'REQUEST_LOAN_APPLICATION' as const;
export const REQUEST_LOAN_APPLICATION_SUCCESS =
  'REQUEST_LOAN_APPLICATION_SUCCESS' as const;
export const REQUEST_LOAN_APPLICATION_FAILED =
  'REQUEST_LOAN_APPLICATION_FAILED' as const;

export const START_EXTERNAL_LOAN_APPLICATION =
  'START_EXTERNAL_LOAN_APPLICATION' as const;
export const START_EXTERNAL_LOAN_APPLICATION_SUCCESS =
  'START_EXTERNAL_LOAN_APPLICATION_SUCCESS' as const;
export const START_EXTERNAL_LOAN_APPLICATION_FAILED =
  'START_EXTERNAL_LOAN_APPLICATION_FAILED' as const;

export const POLL_LOAN_APPLICATION_START =
  'POLL_LOAN_APPLICATION_START' as const;
export const POLL_LOAN_APPLICATION_COMPLETE =
  'POLL_LOAN_APPLICATION_COMPLETE' as const;
export const POLL_LOAN_APPLICATION_CANCELED =
  'POLL_LOAN_APPLICATION_CANCELED' as const;
