import {
  Cloud$Product,
  Cloud$Interest,
  Cloud$Package,
  Cloud$PurchaseModel,
} from '@otovo/shared/types/cloudApi';
import { SurfaceConfiguration } from '../type';
import getDefaultPackage from './getDefaultPackage';
import getSurfaceConfigurationList from './getSurfaceConfigurationList';
import filterPackagesByHardware from './filterPackagesByHardware';

function getPackageById(
  interest: Cloud$Interest,
  packageId: string,
): Cloud$Package {
  const _package = interest.products
    .flatMap((product) => product.packages)
    .find((pkg) => pkg.id === packageId);

  if (!_package) {
    throw new Error(
      `Package with ID ${packageId} not found in interest ${interest.id}`,
    );
  }

  return _package;
}

function productWithName(
  products: Array<Cloud$Product>,
  name: string | null | undefined,
) {
  return products.find((product) => product.name === name);
}

function getSelectedProduct(
  interest: Cloud$Interest,
  preferredProductName: string | null,
): Cloud$Product {
  const preferredProduct = productWithName(
    interest.products,
    preferredProductName,
  );
  const defaultProduct = interest.products[0];
  const selectedProduct = preferredProduct || defaultProduct;

  if (!selectedProduct) {
    throw new Error(
      `Failed to get selected product from interest ${interest.id}`,
    );
  }

  return selectedProduct;
}

function getSelectedPackage({
  selectedProduct,
  preferredPackageId,
  preferredPackageSlug,
  preferBatteryPackage,
  interest,
}: {
  selectedProduct: Cloud$Product;
  preferredPackageId: string | null;
  preferredPackageSlug: string | null;
  preferBatteryPackage: boolean | null;
  interest: Cloud$Interest;
}): Cloud$Package {
  if (preferredPackageId) {
    return getPackageById(interest, preferredPackageId);
  }

  const packagesWithPreferredHardware = filterPackagesByHardware({
    packages: selectedProduct.packages,
    battery: !!preferBatteryPackage,
  });

  const preferredPackage = packagesWithPreferredHardware.find(
    (pkg: Cloud$Package) => pkg.package_size === preferredPackageSlug,
  );

  return (
    preferredPackage ||
    getDefaultPackage(
      selectedProduct.packages,
      interest,
      !!preferBatteryPackage,
    )
  );
}

function getSelectedPurchaseModel(
  interest: Cloud$Interest,
  selectedPackage: Cloud$Package,
  preferredPurchaseModelCode: string | null,
): Cloud$PurchaseModel {
  const preferredPurchaseModel = selectedPackage.price.financing_options.find(
    (purchaseModel) =>
      purchaseModel.purchase_model_code === preferredPurchaseModelCode,
  );
  const defaultPurchaseModel = selectedPackage.price.financing_options[0];
  const selectedPurchaseModel = preferredPurchaseModel || defaultPurchaseModel;
  if (!selectedPurchaseModel) {
    throw new Error(
      `Could not find purchase model for ${selectedPackage.product_name}_${selectedPackage.package_size} in interest ${interest.id}`,
    );
  }
  return selectedPurchaseModel;
}

type UserSelectionArgs = {
  preferredProductName?: string | null | undefined;
  preferredPackageId?: string | null | undefined;
  preferredPackageSlug?: string | null | undefined;
  preferBatteryPackage?: boolean | null | undefined;
  preferredPurchaseModelCode?: string | null | undefined;
};

export type UserSelections = {
  selectedPackage: Cloud$Package | null;
  selectedProduct: Cloud$Product | null;
  selectedPurchaseModel: Cloud$PurchaseModel | null;
  surfaceConfigurationList: Array<SurfaceConfiguration>;
};

export default function getUserSelections(
  interest: Cloud$Interest | null | undefined,
  {
    preferredPackageId = null,
    preferredProductName = null,
    preferredPackageSlug = null,
    preferredPurchaseModelCode = null,
    preferBatteryPackage = false,
  }: UserSelectionArgs,
): UserSelections {
  if (!interest) {
    throw new Error('Unable to get user selections without interest data');
  }

  if (!interest.products.length) {
    // Set everything to null, as we don't have products or packages yet.
    return {
      selectedProduct: null,
      selectedPackage: null,
      selectedPurchaseModel: null,
      surfaceConfigurationList: [],
    };
  }

  const selectedProduct = getSelectedProduct(interest, preferredProductName);

  const selectedPackage = getSelectedPackage({
    selectedProduct,
    preferredPackageId,
    preferredPackageSlug,
    preferBatteryPackage: !!preferBatteryPackage,
    interest,
  });

  const selectedPurchaseModel = getSelectedPurchaseModel(
    interest,
    selectedPackage,
    preferredPurchaseModelCode,
  );

  const surfaceConfigurationList = getSurfaceConfigurationList(
    interest,
    selectedPackage,
  );

  return {
    selectedProduct,
    selectedPackage,
    selectedPurchaseModel,
    surfaceConfigurationList,
  };
}
