import { Otovo$Config } from '../../types/otovoweb';

function resolveTagManagerID(config: Otovo$Config) {
  if (!config?.BU_CONFIG) {
    // Issue with dynamic statically generated pages causes config to be
    // undefined for some reason. Adding a defensive check until we figure out
    // why this is.
    return null;
  }

  const { BU_CONFIG } = config;

  switch (BU_CONFIG.type) {
    case 'OWN_AND_OPERATE':
    case 'PLATFORM_PARTNER':
      if (BU_CONFIG.slug === 'santander-br') {
        // This will enable GTM for Santander in both staging and production.
        // Note: Their "production" deployment is actually linked to a preview deployment.
        // Sorry if this is confusing, ask @rix1 for clarification.
        return BU_CONFIG.gtm_id;
      }
      // BU_CONFIG currently doesn't have env support (same config in
      // staging/prod), so GTM for partners can only be used in production.
      return process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
        ? BU_CONFIG.gtm_id
        : null;
    case 'WHITE_LABEL':
      if (
        BU_CONFIG.market === 'br' &&
        process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
      ) {
        // Hacking in support for staging-only testing 🙈
        return 'GTM-N3VWC45';
      }
      return null;
    case 'LEAD_PARTNER':
      if (BU_CONFIG.slug === 'santander-br') {
        return process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
          ? 'GTM-W47JK2Z' // PROD container ID - because Santander is running in preview environment
          : BU_CONFIG.gtm_id;
      }
      return null;
    default:
      return null;
  }
}

export default resolveTagManagerID;
