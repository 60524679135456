export const RESET_INTEREST_STATE = 'RESET_INTEREST_STATE' as const;
export const REQUEST_CREATE_INTEREST = 'REQUEST_CREATE_INTEREST' as const;
export const REQUEST_CREATE_INTEREST_SUCCESS =
  'REQUEST_CREATE_INTEREST_SUCCESS' as const;
export const REQUEST_CREATE_INTEREST_FAILED =
  'REQUEST_CREATE_INTEREST_FAILED' as const;

export const POLL_INTEREST_START = 'POLL_INTEREST_START' as const;
export const POLL_INTEREST_CANCELED = 'POLL_INTEREST_CANCELED' as const;
export const POLL_INTEREST_COMPLETE = 'POLL_INTEREST_COMPLETE' as const;
export const POLL_UPDATE_INTEREST_START = 'POLL_UPDATE_INTEREST_START' as const;
export const POLL_UPDATE_INTEREST_SUCCESS =
  'POLL_UPDATE_INTEREST_SUCCESS' as const;

export const REQUEST_INTEREST = 'REQUEST_INTEREST' as const;
export const REQUEST_INTEREST_SUCCESS = 'REQUEST_INTEREST_SUCCESS' as const;
export const REQUEST_INTEREST_FAILED = 'REQUEST_INTEREST_FAILED' as const;

export const REQUEST_UPDATE_SURFACES = 'REQUEST_UPDATE_SURFACES' as const;
export const REQUEST_UPDATE_SURFACES_SUCCESS =
  'REQUEST_UPDATE_SURFACES_SUCCESS' as const;
export const REQUEST_UPDATE_SURFACES_FAILED =
  'REQUEST_UPDATE_SURFACES_FAILED' as const;

export const SELECT_PACKAGE = 'SELECT_PACKAGE' as const;
export const SELECT_PURCHASE_MODEL = 'SELECT_PURCHASE_MODEL' as const;
export const CHANGE_SURFACE_ROOFING = 'CHANGE_SURFACE_ROOFING' as const;
export const TOGGLE_SURFACE = 'TOGGLE_SURFACE' as const;

export const REQUEST_ADD_CONSUMPTION = 'REQUEST_ADD_CONSUMPTION' as const;
export const REQUEST_ADD_CONSUMPTION_SUCCESS =
  'REQUEST_ADD_CONSUMPTION_SUCCESS' as const;
export const REQUEST_ADD_CONSUMPTION_FAILED =
  'REQUEST_ADD_CONSUMPTION_FAILED' as const;
