import { Cloud$Package } from '@otovo/shared/types/cloudApi';

type Args = {
  packages: Array<Cloud$Package>;
  battery?: boolean;
};

const filterPackagesByHardware = ({
  packages,
  battery = false,
}: Args): Array<Cloud$Package> => {
  if (battery) {
    return packages.filter(
      (pkg: Cloud$Package) => !!pkg.price.equipment.batteries.length,
    );
  }

  return packages.filter(
    (pkg: Cloud$Package) => !pkg.price.equipment.batteries.length,
  );
};

export default filterPackagesByHardware;
