import { NextRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

import NextPageRoot from '@otovo/shared/NextPageRoot';
import { setHeadBaseUrl } from '@otovo/shared/components/Head/headContext';
import { useAnalytics } from '@otovo/shared/hooks/useAnalytics';
import { utmToGa } from '@otovo/shared/lib/analytics/ga';
import UtmLocalStorage from '@otovo/shared/lib/analytics/queryParams/UtmLocalStorage';
import resolveTagManagerID from '@otovo/shared/lib/analytics/resolveTagManagerID';
import { refreshWidget } from '@otovo/shared/lib/chatUtils';
import loglevelPlugin from '@otovo/shared/lib/sentry/loglevelPlugin';
import throwForFalsy from '@otovo/shared/lib/throwForFalsy';

import GoogleTagManagerAndOneTrustScripts from 'otovoweb/src/lib/GoogleTagManagerAndOneTrustScripts';
import workaroundBrowserExtensions from 'otovoweb/src/workaroundBrowserExtensions';

import useIframeClientId from '../components/useIframeClientId';
import { reduxWrapper } from '../store';

import 'otovoweb/src/styles/globals.css';

type Props = {
  Component: any;
  pageProps: any;
  router: NextRouter;
  err: any;
};

/**
 * Next.js uses the App component to initialize pages.
 * You can override it and control the page initialization. Which allows you to do amazing things like
 *  - Persisting layout between page changes
 *  - Keeping state when navigating pages
 *  - Custom error handling using componentDidCatch
 *  - Inject additional data into pages
 *  - Add global CSS
 */

function BreezeApp({ Component, pageProps, router, err }: Props) {
  // `err` is a workaround for https://github.com/vercel/next.js/issues/8592
  const {
    asPath,
    query,
    query: { cookieKey },
  } = router;
  const { config } = pageProps;
  const gtmId = resolveTagManagerID(config);

  useEffect(() => {
    const handleRouteChange = () => {
      refreshWidget();
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    loglevelPlugin.initialize();
  });

  const getClientId = useIframeClientId({
    acceptedDomain: config.STOREFRONT_URL,
    market: config.BU_CONFIG.market,
  });

  useAnalytics({ config, path: asPath, getClientId, isGTMEnabled: !!gtmId });

  useEffect(() => {
    if (/Chrom/.test(window.navigator.userAgent)) {
      workaroundBrowserExtensions();
    }
  }, []);

  useEffect(() => {
    UtmLocalStorage.store(query);
    utmToGa(query);
  }, [query]);

  setHeadBaseUrl(throwForFalsy(process.env.NEXT_PUBLIC_BREEZE_URL));

  // See search-ssr.js for more details
  const doNotTrack = query.dnt === 'true';

  return (
    <NextPageRoot configValues={config}>
      {!!gtmId && !doNotTrack && (
        <GoogleTagManagerAndOneTrustScripts
          googleTagManagerId={gtmId}
          query={asPath.split('?')[1]}
          cookieKey={cookieKey as string}
        />
      )}
      {/* Optional tracking script for partners */}
      {process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' &&
        config.BU_CONFIG.tracking_script_src && (
          <Script
            src={config.BU_CONFIG.tracking_script_src}
            strategy="afterInteractive"
          />
        )}
      <Component {...pageProps} err={err} />
      <div id="modal-root" />
    </NextPageRoot>
  );
}

export default reduxWrapper.withRedux(BreezeApp) as any;
