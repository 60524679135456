export const REQUEST_CONTRACT = 'REQUEST_CONTRACT' as const;
export const REQUEST_CONTRACT_SUCCESS = 'REQUEST_CONTRACT_SUCCESS' as const;
export const REQUEST_CONTRACT_FAILED = 'REQUEST_CONTRACT_FAILED' as const;

export const SIGN_CONTRACT = 'SIGN_CONTRACT' as const;
export const SIGN_CONTRACT_SUCCESS = 'SIGN_CONTRACT_SUCCESS' as const;
export const SIGN_CONTRACT_FAILED = 'SIGN_CONTRACT_FAILED' as const;

export const POLL_CONTRACT_START = 'POLL_CONTRACT_START' as const;
export const POLL_CONTRACT_COMPLETE = 'POLL_CONTRACT_COMPLETE' as const;
export const POLL_CONTRACT_CANCELED = 'POLL_CONTRACT_CANCELED' as const;

export const PREVIEW_CONTRACT = 'PREVIEW_CONTRACT' as const;
export const PREVIEW_CONTRACT_SUCCESS = 'PREVIEW_CONTRACT_SUCCESS' as const;
export const PREVIEW_CONTRACT_FAILED = 'PREVIEW_CONTRACT_FAILED' as const;
