import { Box } from '@otovo/rainbow';
import { Rainbow$Props } from '../types/rainbow';

type Props = { children: React.ReactNode } & Rainbow$Props;

const ContentWrap = ({ children, ...rest }: Props) => (
  <Box
    display="flex"
    flexDirection={{ s: 'column', s3: 'row' }}
    justifyContent="space-between"
    alignItems="center"
    maxWidth="18"
    width="100%"
    px={{
      s: 4,
      s3: 7,
      l3: 4,
    }}
    mx="auto"
    {...rest}
  >
    {children}
  </Box>
);

export default ContentWrap;
